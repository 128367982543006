<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnRentOperationalCreate" title="Locação" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="rentOperationalCreate" :clicked="create" />
      </div>
      <div slot="content-filter-horizontal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="3" xl="3">
            <DateTime title="Período (Saída e Retorno)" fieldTarget="period" format="DD/MM/YYYY" type="date"
              :range="true" :confirm="true" confirmText="Filtrar" placeholder :required="false" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <RadioGroup title="Status" fieldTarget="status" :options="[
              { text: 'Orçamento', value: 'Orçamento' },
              { text: 'Aprovada', value: 'Aprovada' },
              { text: 'Reprovada', value: 'Reprovada' },
            ]" v-model="status" />
          </b-col>
        </b-row>
      </div>
    </ViewTemplateWithTable>
    <Modal title="Tem certeza que deseja excluir?" :width="530" v-show="showModal('removeRent')">
      <Alert type="warning">
        <span>
          Atenção, ao excluir uma Locação alguns processos (se houver) serão executados, esta ação é irreversível...
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="remove" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "RentOperationalListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button,
    DateTime,
    RadioGroup
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Operacional",
        title: "Locação",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/operational/rent/get-all",
        urlDeleteAllApi: "/api/v1/operational/rent/delete",
        showChecks: false,
        headerTable: [
          {
            title: "",
            field: "step",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleBody: "width: 30px;",
          },
          {
            title: "Número/Data",
            field: "numberAndDate",
            type: "text",
            iconSearch: true,
          },
          {
            field: "fullName",
            fieldSecond: "identification",
            title: "Nome",
            type: "link",
            eventName: "rentOperationalUpdate",
            iconSearch: true,
          },
          {
            field: "deliveryDateHourStr",
            fieldSecond: "startDateHourStr",
            title: "Saída e Início",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Fim e Retorno",
            field: "endDateHourStr",
            fieldSecond: "devolutionDateHourStr",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusRentalName",
            title: "Status",
            type: "class",
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Orçamento",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Aprovada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Reprovada",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Finalizada",
              },
            ],
          },
          {
            type: "button",
            typeButton: "default",
            button: {
              classIcon: "fa-solid fa-trash",
              type: "danger",
              size: "small",
              eventName: "removeRent",
            },
          },
        ],
      },
      propsParam: {
        showAll: false
      },
      status: "",
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["deleteAllApi"]),
    create() {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "rentOperationalCreate",
        });
        self.removeLoading(["btnRentOperationalCreate"]);
      }, 200);
    },
    remove() {
      let params = {
        url: this.templateList.urlDeleteAllApi,
        selected: [this.id],
      };
      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("removeRent");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "removeRent") {
          this.id = event.data.rentId;
          this.openModal("removeRent");
        }
        if (event.name == "rentOperationalUpdate") {
          let self = this;
          setTimeout(function () {
            self.$router.push({
              name: "rentOperationalUpdate",
              params: { id: event.data.rentId, periodRentId: event.data.periodRentId }
            });
            self.removeLoading(["btnRentOperationalCreate"]);
          }, 200);
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
  border-radius: 50px !important;
}

.div-tag {
  margin-bottom: 10px;
}

.rent-progress {
  color: #4bb4e2;
}

.rent-accepted {
  color: #38A169;
}

.rent-rejected {
  color: #FF5454;
}

.rent-icon-close {
  color: red;
}
</style>